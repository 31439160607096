@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_regular_400-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_regular_400-webfont.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_regular_400-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_regular_400-webfont.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_regular_400-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_regular_400-webfont.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_medium_500-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_medium_500-webfont.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_medium_500-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_medium_500-webfont.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_semibold_600-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_semibold_600-webfont.woff")
      format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/montserrat/montserrat_semibold_600-webfont.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat_semibold_600-webfont.woff")
      format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/slick.woff") format("woff");
}

/* Video size rendering */
.media {
  height: 210px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1248px) {
  .media {
    height: 420px;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 16px;
    height: 50px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
    max-height: 100px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #cfd3e5;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #07116e;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  /* Makes automatically all h tags to use applied colour initially */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-black;
  }
  p {
    @apply font-normal;
  }
}

@layer components {
  /*      CSS FOR FLOATING LABELS ON FORMS */
  .float-input-container {
    @apply relative mx-0 flex flex-col;
  }
  .float-input-container textarea {
    @apply h-[7.125rem] w-full resize-none rounded-3xl  border-0 bg-blue-5 py-[1rem] px-[1.563rem] outline-0 hover:ring-[1px] hover:ring-blue-10 focus:border-0 focus:bg-blue-10 focus:outline-0 focus:ring-0;
  }

  .float-input-container input {
    @apply h-[2.75rem] w-full rounded-full bg-blue-5 py-2 px-[1.563rem] text-p;
  }
  .float-input-container input:hover {
    @apply bg-blue-10 ring-[1px] ring-blue-20;
  }
  .float-input-container label {
    @apply pointer-events-none absolute -top-[2px] py-0 px-[1.563rem] text-p text-darkgray;
    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  .float-input-container:focus-within label {
    @apply rounded-md bg-white text-black;
    padding: 4px 8px;
    transform: translate(20px, -9px) scale(0.75);
  }
  .active-float-label {
    @apply rounded-md bg-white text-black;
    padding: 4px 8px !important;
    transform: translate(20px, -9px) scale(0.75) !important;
  }

  .float-input-container input:-webkit-autofill ~ label {
    /* Makes labels float on autofill */
    @apply rounded-md bg-white text-black;
    padding: 4px 8px;
    transform: translate(20px, -9px) scale(0.75);
  }

  /* END OF FLOAT CSS */
  .error-field {
    @apply ring-[1px] ring-negative-100;
  }
  .error-text {
    @apply mt-0.5 px-[25px] text-[0.7rem] text-negative-100;
  }
  .input-field {
    @apply flex h-14 w-full rounded-full bg-blue-5 px-[25px] hover:ring-[1px] hover:ring-blue-10 focus:bg-blue-10;
  }
  .digit-field {
    @apply m-0.5 h-[54px] w-[100%] max-w-[54px] rounded-full border-none bg-blue-5 p-5 text-center text-[18px] hover:ring-[1px] hover:ring-blue-10 focus:bg-blue-10;
  }
  .textarea-field {
    @apply mt-6 flex h-28 w-full resize-none rounded-3xl border-0 bg-blue-5 px-[36px] pt-[18px] outline-0 hover:ring-[1px] hover:ring-blue-10 focus:border-0 focus:bg-blue-10 focus:outline-0 focus:ring-0;
  }
  .input-label {
    @apply mb-[0.375rem] mt-7 px-[36px] text-[0.75rem] font-medium;
  }

  /* Input settings below are for css when autofilling input fields */
  /* For clas .autofill-bg-white  */
  .autofill-bg-white input,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  /* The rest of the auiofill fields */
  input:-webkit-autofill:not(.autofill-bg-white),
  input:-webkit-autofill:hover:not(.autofill-bg-white),
  input:-webkit-autofill:focus:not(.autofill-bg-white),
  input:-webkit-autofill:active:not(.autofill-bg-white) {
    -webkit-box-shadow: 0 0 0 30px #f3f4f8 inset !important;
  }
  input:focus {
    outline: none !important;
  }
  /* ^^^ */

  .primary-button-color-scheme {
    @apply bg-orange text-white hover:bg-orange desktop:bg-orange-100;
  }
  .secondary-button-color-scheme {
    @apply bg-darkblue text-white hover:bg-darkblue desktop:bg-lightblue;
  }
  .secondary-light-button-color-scheme {
    @apply bg-blue-20 text-darkblue hover:bg-blue-20 desktop:bg-blue-10;
  }
  .tertiary-button-color-scheme {
    @apply bg-orange-20 text-orange hover:bg-orange-20 desktop:bg-orange-10;
  }

  /* Fix for windows 125% default scaling */
  @media (-webkit-device-pixel-ratio: 1.25) {
    .scaling {
      zoom: 0.95;
    }
  }
}
